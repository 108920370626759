$(document).ready(function(){
    $('.editCamera').click(function(){
        var url = $(this).data('url');
        var name = $(this).data('name');
        var animal = $(this).data('animal');
        var id = $(this).data('id');
        $('#camera_edit_url').val(url);
        $('#camera_edit_name').val(name);
        $('#camera_edit_id').val(id);
        $('#camera_edit_animal > option').each(function(){
            if($(this).text() == animal){
                $(this).attr('selected','selected');
            }
          })
    });
    $('input[name=cam]').change(function() {
		if (!$(this).is(':checked')) {
            $("#clav").css({display:"none"});
            if($(window).width()>1100){
               $("#overlayNest").css({top:"32rem",right:"6rem"});
            }else if($(window).width()>900){
               $("#overlayNest").css({top:"20rem",right:"8rem"});
            }else if($(window).width()>850){
               $("#overlayNest").css({top:"20rem",right:"-1rem"});
            }else if($(window).width()>768){
               $("#overlayNest").css({top:"19rem",right:"-2rem"});
            }else{
               $("#overlayNest").css({top:"",right:""});
            }
            
        }else{
           $("#overlayNest").css({top:"",right:""});
           $("#clav").css({display:"block"});
		}
	});





    let chaut = $('#hauts');
    let cdroit = $('#droits');


    ads(chaut);
    ads(cdroit);

    function ads(slide){
        if(slide.length < 1){
            return
        }
        if ($(slide).find('.carousel-item').length === 1){
            let item = $(slide).find('.carousel-item');
            slideAjax(item,'view');
        }else{

            slide.on('slide.bs.carousel', function () {
                let currentIndex = slide.find('.carousel-item.active');
                if(currentIndex.data('visit') === false) {
                    slideAjax(currentIndex,'view');
                    currentIndex.data('visit',true);
                }
            });

        }

        slide.on('click','.carousel-item', function () {
            slideAjax($(this),'click');
        });

    }

    function slideAjax(item,type){
        let pub = item.data('pub');
        let user = item.data('user');
        $.ajax({
            url:        '/ads_store',
            type:       'POST',
            dataType:   'json',
            data: {
                "pub-id": pub,
                "user-id": user,
                "type": type
            },
            async:      true,

            success: function(data, status) {
                console.log('success');
            },
            error : function(xhr, textStatus, errorThrown) {
                console.log('Ajax request failed.');
            }
        });
    }

});